import { createApp } from 'vue'
import App from './App.vue'
import BootstrapVueNext from 'bootstrap-vue-next'
import moment from 'moment'
import router from './router'
import store from './store'

import 'bootswatch/dist/flatly/bootstrap.min.css'
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css'

moment.locale('fr')

let app = createApp(App)
app.use(BootstrapVueNext)
app.use(router)
app.use(store)
app.mount('#app')
app.config.globalProperties.$moment=moment
