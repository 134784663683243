import axios from 'axios';

const API_URL = process.env.VUE_APP_API_URL + '/users/';

class AuthService {

  login(user) {
    return axios
      .post(API_URL + 'signin', {
        email: user.email,
        password: user.password
      })
      .then(response => {
        if (response.data.token) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  logout() {
    localStorage.removeItem('user');
  }

  register(user) {
    return axios.post(API_URL + 'signup', {
      firstname: user.firstname,
      lastname: user.lastname,
      email: user.email,
      password: user.password,
      admin: false
    });
  }

}

export default new AuthService();
