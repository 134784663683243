import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_URL + '/books/';

class CatalogService {

	addBook(book) {
		return axios.post(API_URL + 'add', {
			authors: book.authors,
			classification: book.classification,
			comments: book.comments,
			reference: book.reference,
			inserted: new Date(),
			isbn: book.isbn,
			shelf: book.shelf,
			title: book.title
		}, { headers: authHeader() })
	}

	borrowBook(id, book) {
		return axios.put(API_URL + id, {
			borrowedBy: book.borrowedBy,
			borrowingDate: book.borrowingDate,
			comments: book.comments,
		}, { headers: authHeader() })
	}

	download() {
		return axios.get(API_URL + 'download', { headers: authHeader() })
	}

	getAllBooks() {
		return axios.get(API_URL)
	}

	getAvailableBooks() {
		return axios.get(API_URL + 'available')
	}

	getBooksBorrowedBy(userName) {
		return axios.get(API_URL + 'borrowed/' + userName, { headers: authHeader() });
	}

	getBookById(id) {
		return axios.get(API_URL + id)
	}

	getBookByTitle(title) {
		return axios.get(API_URL + 'title/' + title)
	}

	getOneBook(id) {
		return axios.get(API_URL + id)
	}

	getRecentAcquisitions(skip, limit) {
		return axios.get(API_URL + 'recent/' + skip + '/' + limit)
	}

	deleteBook(id) {
		return axios.delete(API_URL + id, { headers: authHeader() })
	}

	returnBook(id) {
		return axios.put(API_URL + id, {
			borrowedBy: '',
			borrowingDate: null
		}, { headers: authHeader() })
	}

	updateBook(id, book) {
		return axios.put(API_URL + id, {
			authors: book.authors,
			classification: book.classification,
			comments: book.comments,
			isbn: book.isbn,
			reference: book.reference,
			shelf: book.shelf,
			title: book.title
		}, { headers: authHeader() })
	}

}

export default new CatalogService()
