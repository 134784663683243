import { createRouter, createWebHistory } from 'vue-router'
import { Buffer } from 'buffer'
import store from '../store'

// lazy-loaded
const BookAdd = () => import('../components/BookAdd.vue')
const BookBorrow = () => import('../components/BookBorrow.vue')
const BookDetails = () => import('../components/BookDetails.vue')
const BookUpdate = () => import('../components/BookUpdate.vue')
const BorrowerBooks = () => import('../components/BorrowerBooks.vue')
const Borrowers = () => import('../components/Borrowers.vue')
const Catalog = () => import('../components/Catalog.vue')
const Home = () => import('../components/Home.vue')
const Login = () => import('../components/Login.vue')
const Profile = () => import('../components/Profile.vue')
const Register = () => import('../components/Register.vue')
const SearchById = () => import('../components/SearchById')
const Users = () => import('../components/Users.vue')

const routes = [
	{
		path: '/',
		name: 'home',
		component: Home
	},
	{
		path: '/login',
		name: 'login',
		component: Login
	},
	{
		path: "/profile",
		name: "profile",
		component: Profile,
	},
	{
		path: "/register",
		name: 'register',
		component: Register,
	},
	{
		path: '/search-by-id',
		name: 'search-by-id',
		component: SearchById,
	},
	{
		path: '/borrowers',
		name: 'borrowers',
		component: Borrowers,
	},
	{
		path: "/borrower/:borrower",
		name: "borrower-books",
		component: BorrowerBooks
	},
	{
		path: "/users",
		name: "users",
		component: Users,
	},
	{
		path: "/catalog",
		name: "catalog",
		component: Catalog,
	},
	{
		path: "/catalog/:id",
		name: "book-details",
		component: BookDetails,
	},
	{
		path: '/book/add',
		name: 'book-add',
		component: BookAdd,
	},
	{
		path: '/book/update/:id',
		name: 'book-update',
		component: BookUpdate,
	},
	{
		path: '/book/borrow',
		name: 'book-borrow',
		component: BookBorrow,
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

function parseJwt (token) {
	const base64Url = token.split('.')[1]
	const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
	const jsonPayload = decodeURIComponent(
		Buffer
			.from(base64, "base64")
			.toString("ascii")
			.split('')
			.map( c => {
				return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
			})
			.join('')
		)

	return JSON.parse(jsonPayload)
}

router.beforeEach((to, from, next) => {

	// list of public pages
	const publicPages = [
		'login', 'register', 'home',
		'catalog', 'book-details'
	]

	// boolean to know if authentication is required or not
	const authRequired = !publicPages.includes(to.name)

	// check if user is logged in
	const loggedIn = localStorage.getItem('user')

	// if logged in, check validity of the token
	if (loggedIn) {
		const jwtPayload = parseJwt(localStorage.user)
		if (jwtPayload.exp < Date.now()/1000) {
			// token expired
			store.dispatch('auth/logout')
			if (authRequired)
				next('/login')
		}
	}

	// trying to access a restricted page + not logged in?
	// redirect to login page
	if (authRequired && !loggedIn) {
		next('/login')
	} else {
		next()
	}

})

export default router
