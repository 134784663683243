<template>
	<nav class="navbar navbar-expand bg-primary" data-bs-theme="dark">
		<div class="container-fluid">
			<router-link to="/" class="navbar-brand">
				<img src="./assets/logo.png" height="40" />
			</router-link>
			<button
				class="navbar-toggler"
				type="button"
				data-bs-toggle="collapse"
				data-bs-target="#navbarSupportedContent"
				aria-controls="navbarSupportedContent"
				aria-expanded="false"
				aria-label="Toggle navigation">
				<span class="navbar-toggler-icon"></span>
			</button>
			<div class="collapse navbar-collapse" id="navbarSupportedContent">
				<!-- Common links -->
				<ul class="navbar-nav me-auto">
					<li class="nav-item">
						<router-link to="/" class="nav-link icon-link">
							<mdi-home/> Accueil
						</router-link>
					</li>
					<li class="nav-item">
						<router-link to="/catalog" class="nav-link icon-link">
							<mdi-book-open-page-variant-outline/> Catalogue
						</router-link>
					</li>
					<li class="nav-item" v-if="currentUser && isAdmin">
						<router-link to="/borrowers" class="nav-link icon-link">
							<mdi-bookshelf/> Emprunts
						</router-link>
					</li>
					<BNavItemDropdown v-if="currentUser">
						<template #button-content>
							<div class="icon-link">
								<mdi-tools/> Outils
							</div>
						</template>
						<BDropdownItem>
							<router-link to="/search-by-id" class="nav-link icon-link">
								<mdi-file-search/> Recherche par identifiant
							</router-link>
						</BDropdownItem>
						<BDropdownItem v-if="isAdmin">
							<router-link to="/book/borrow" class="nav-link icon-link">
								<mdi-bag-suitcase/> Déclarer un prêt
							</router-link>
						</BDropdownItem>
						<BDropdownItem v-if="isAdmin">
							<router-link to="/book/add" class="nav-link icon-link">
								<mdi-plus/> Ajouter un exemplaire
							</router-link>
						</BDropdownItem>
						<BDropdownItem v-if="isAdmin">
							<router-link to="/users" class="nav-link icon-link">
								<mdi-account-multiple/> Utilisateur·rices
							</router-link>
						</BDropdownItem>
						<BDropdownItem v-if="isAdmin">
							<a class="nav-link icon-link" @click.prevent="download">
								<mdi-download/> Télécharger la base
							</a>
						</BDropdownItem>
					</BNavItemDropdown>
				</ul>

				<!-- if not logged in -->
				<ul v-if="!currentUser" class="navbar-nav ml-auto">
					<li class="nav-item">
						<router-link to="/register" class="nav-link icon-link">
							<mdi-account-plus/> Inscription
						</router-link>
					</li>
					<li class="nav-item">
						<router-link to="/login" class="nav-link icon-link">
							<mdi-login/> Connexion
						</router-link>
					</li>
				</ul>

				<!-- if logged in -->
				<ul v-if="currentUser" class="navbar-nav ml-auto">
					<li class="nav-item">
						<router-link to="/profile" class="nav-link icon-link">
							<mdi-account/>
							{{ currentUser.userName }}
						</router-link>
					</li>
					<li class="nav-item">
						<a class="nav-link icon-link" @click.prevent="logOut">
							<mdi-logout/> Déconnexion
						</a>
					</li>
				</ul>

			</div>
		</div>
	</nav>

	<router-view />
</template>

<script>

import MdiAccount from '~icons/mdi/account'
import MdiAccountMultiple from '~icons/mdi/account-multiple'
import MdiAccountPlus from '~icons/mdi/account-plus'
import MdiBookshelf from '~icons/mdi/bookshelf'
import MdiBookOpenPageVariantOutline from '~icons/mdi/book-open-page-variant-outline'
import MdiDownload from '~icons/mdi/download'
import MdiFileSearch from '~icons/mdi/file-search'
import MdiHome from '~icons/mdi/home'
import MdiLogin from '~icons/mdi/login'
import MdiLogout from '~icons/mdi/logout'
import MdiBagSuitcase from '~icons/mdi/bag-suitcase'
import MdiPlus from '~icons/mdi/plus'
import MdiTools from '~icons/mdi/tools'

import CatalogService from './services/catalog.service'

export default {
	components: {
		MdiAccount,
		MdiAccountMultiple,
		MdiAccountPlus,
		MdiBookshelf,
		MdiBookOpenPageVariantOutline,
		MdiDownload,
		MdiFileSearch,
		MdiHome,
		MdiLogin,
		MdiLogout,
		MdiBagSuitcase,
		MdiPlus,
		MdiTools
	},
	data() {
		return {
			message: ''
		}
	},
	computed: {
		isAdmin() {
			return this.$store.state.auth.user.admin
		},
		currentUser() {
			return this.$store.state.auth.user
		}
	},
	methods: {
		download() {
			if (this.isAdmin) {
				CatalogService.download()
					.then(response => {
						const link = document.createElement('a')
						link.href = window.URL.createObjectURL(
							new Blob([ JSON.stringify(response.data) ])
						)
						link.setAttribute('download', 'books.json')
						document.body.appendChild(link)
						link.click()
					})
					.catch( error => {
						this.message =
							(error.response &&
								error.response.data &&
								error.response.data.message) ||
							error.message ||
							error.toString()
				})
			} else {
				this.$router.push('/login')
			}
		},
		logOut() {
			this.$store.dispatch('auth/logout')
			this.$router.push('/')
		}
	}
}

</script>
